import React from "react";

const Title = ({ name, description }) => {
  return (
    <div className="title">
      {/* {docs && docs.length > 0 && */}
      <h2>{name}</h2>
      <p> {description}.</p>
      {/* } */}
    </div>
  );
};

export default Title;
