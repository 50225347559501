import React from "react";
// import useFirestore from "../hooks/useFirestore";
import { motion } from "framer-motion";

const ImageGrid = ({ setSelectedPostIndex, posts }) => {
  // const { docs } = useFirestore(username, bundleName);

  return (
    <div className="img-grid">
      {posts &&
        posts.length > 0 &&
        posts.map((post, index) => (
          <motion.div className="img-wrap" key={post.media.url} layout whileHover={{ opacity: 1 }} s onClick={() => setSelectedPostIndex(index)}>
            <motion.img src={post.media.url} alt="uploaded pic" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1 }} />
          </motion.div>
        ))}
    </div>
  );
};

export default ImageGrid;
