import React from "react";
import { motion } from "framer-motion";

const Modal = ({ setSelectedPost, selectedPost, showNextPost, showPrevPost, canShowNextPost, canShowPrevPost, setSelectedPostIndex }) => {
  const handleClick = (e) => {
    console.log(e.target.classList);
    if (e.target.classList.contains("post-container") || e.target.classList.contains("arrows")) {
      close();
    }
  };

  const close = () => {
    setSelectedPostIndex(null);
    setSelectedPost(null);
  };

  const getDateString = () => {
    let now = new Date().getTime() / 1000;
    let diff = now - selectedPost.createdAt;

    switch (true) {
      case diff < 60:
        return "0 minutes ago";
      case diff < 3600:
        let numMinutes = Math.floor(diff / 60);
        return `${numMinutes} ${numMinutes == 1 ? "minute" : "minutes"} ago`;
      case diff < 86400:
        let numHours = Math.floor(diff / 3600);
        return `${numHours} ${numHours == 1 ? "hour" : "hours"} ago`;
      case diff < 86400 * 7:
        let numDays = Math.floor(diff / 86400);
        return `${numDays} ${numDays == 1 ? "day" : "days"} ago`;
      default:
        let numWeeks = Math.floor((diff / 86400) * 7);
        return `${numWeeks} ${numWeeks == 1 ? "week" : "weeks"} ago`;
    }
  };

  return (
    <motion.div className="backdrop" onClick={handleClick} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div className="post-container">
        <div className="post">
          <div className="wrapper">
            <motion.img className="largeImage" src={selectedPost.media.url} alt="enlarged pic" />
            <div className="flex-container">
              <img className="profile-image-small" src={selectedPost.media.url} alt="Arrow Right" onClick={showNextPost} />
            </div>
            <div className="caption">
              <p>
                {" "}
                <strong> {selectedPost.username} </strong> {selectedPost.caption !== "" ? "- " + selectedPost.caption : ""}
              </p>
              <div className="date-text">
                <p> {getDateString()} </p>
                <div>
                  <img className="post-option" src={"/images/react.png"} alt="Add Reaction" />
                  <img className="post-option" src={"/images/comment.png"} alt="Add Comment" />
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-options">
            <button className="no-background">
              <img className="close-bottom" src={"/images/close-circle.png"} width="40" height="40" alt="Close" onClick={close} />
            </button>

            <div className="arrows">
              {canShowPrevPost && (
                <button className="left-arrow no-background">
                  <img className="arrow-image m-l" src={"/images/leftArrow.png"} width="40" height="40" alt="Arrow Left" onClick={showPrevPost} />
                </button>
              )}

              {canShowNextPost && (
                <button className="right-arrow no-background">
                  <img className="arrow-image m-r" src={"/images/rightArrow.png"} alt="Arrow Right" onClick={showNextPost} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        <button className="no-background">
          <img className="close" src={"/images/close.png"} alt="Arrow Right" onClick={close} />
        </button>
      </div>
    </motion.div>
  );
};

export default Modal;
