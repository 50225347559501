import React from "react";
import BundleView from "./comps/BundleView";

function App() {
  return (
    <div className="App">
      <h1>Test</h1>
    </div>
  );
}

export default App;
