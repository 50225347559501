// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCGBDDYHONnVWkEeyuQjtE_L3AOekbH2SQ",
  authDomain: "bundle-82eb0.firebaseapp.com",
  projectId: "bundle-82eb0",
  storageBucket: "bundle-82eb0.appspot.com",
  messagingSenderId: "174281109965",
  appId: "1:174281109965:web:f9c578ca6fd735a9d0d484",
  measurementId: "G-1EJ07B8M54",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirestore, projectStorage, timestamp };
