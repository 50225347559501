import { useState, useEffect } from "react";
import { projectFirestore } from "../firebase/config";

const useFirestore = (username, bundleName) => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    if (username == null || bundleName == null) {
      return;
    }
    console.log(bundleName);
    projectFirestore
      .collection("bundles")
      .where("adminUsernames", "array-contains", username)
      .where("isPublic", "==", true)
      .where("webName", "==", bundleName)
      .get()
      .then((querySnapshot) => {
        console.log(querySnapshot);
        querySnapshot.forEach((doc) => {
          console.log("SETTING");
          setDocs([{ ...doc.data(), id: doc.id }]);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }, [username, bundleName]);

  return { docs };
};

export default useFirestore;
