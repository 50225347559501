import React, { useState, useEffect } from "react";
import Title from "./Title";
import ImageGrid from "./ImageGrid";
import Modal from "./Modal";
import useFirestore from "../hooks/useFirestore";

function BundleView() {
  const [selectedPost, setSelectedPost] = useState(null);
  const [selectedPostIndex, setSelectedPostIndex] = useState(null);
  const [posts, setPosts] = useState([]);
  const [username, setUsername] = useState("");
  const [bundleName, setBundleName] = useState("");
  const [description, setDescription] = useState("");
  const { docs } = useFirestore(username, bundleName);

  useEffect(() => {
    const pathComps = window.location.pathname.split("/").filter((n) => n && n !== "users");

    if (pathComps.length > 1) {
      const username = pathComps[0];
      const bundleNameArray = pathComps[1].split("%20");
      const bundleName = bundleNameArray.join(" ");
      setUsername(username);
      setBundleName(bundleName);
    }
  }, []);

  useEffect(() => {
    if (docs && docs.length > 0) {
      setDescription(docs[0].description);
      const posts = docs[0].posts;
      // const Posts = docs[0].posts.map((post) => {
      //   return post.media.url;
      // });
      // console.log(Posts);
      setPosts(posts);
    }
  }, [docs]);

  useEffect(() => {
    setSelectedPost(posts[selectedPostIndex]);
  }, [selectedPostIndex]);

  const showNextPost = () => {
    setSelectedPostIndex(selectedPostIndex + 1);
  };

  const showPrevPost = () => {
    setSelectedPostIndex(selectedPostIndex - 1);
  };

  return (
    <div class="container">
      <Title name={bundleName} description={description} />
      <ImageGrid setSelectedPostIndex={setSelectedPostIndex} posts={posts} />
      {selectedPost && (
        <Modal
          selectedPost={selectedPost}
          setSelectedPost={setSelectedPost}
          setSelectedPostIndex={setSelectedPostIndex}
          showNextPost={showNextPost}
          showPrevPost={showPrevPost}
          canShowNextPost={selectedPostIndex < posts.length - 1}
          canShowPrevPost={selectedPostIndex > 0}
        />
      )}
    </div>
  );
}

export default BundleView;
